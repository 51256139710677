import { github, vsDark } from "@code-surfer/themes";

// export const theme = vsDark;

// export default vsDark;

export default {
    colors: {
      background: "#222",
      text: "#ddd",
      primary: "#a66"
    },
    text: {
        heading: {
            textAlign: "left",
            marginLeft: "100px",
            marginRight: "100px",
        },
    },
    styles: {
    p: {
        margin: "0 100px 50px 100px",
        display: "block"
        },
      ul: {
        margin: "0 100px",
      },
      table: {
        padding: "0 100px",
        boxSizing: "border-box"
      },
      Slide: {
        //   justifiContent
        alignItems: 'flex-start',
        // justifyContent: 'flex-start'
      },
      CodeSurfer: {
        pre: {
          color: "text",
          backgroundColor: "background"
        },
        code: {
          color: "text",
          backgroundColor: "background"
        },
        tokens: {
          "comment cdata doctype": {
            fontStyle: "italic"
          },
          "builtin changed keyword punctuation operator tag deleted string attr-value char number inserted": {
            color: "primary"
          },
          "line-number": {
            opacity: 0.8
          }
        },
        title: {
          backgroundColor: "background",
          color: "text",
          padding: "20px"
        },
        h2: {
          color: "#f00"
        },
        subtitle: {
          color: "#d6deeb",
          backgroundColor: "rgba(10,10,10,0.9)"
        },
        unfocused: {
          // only the opacity of unfocused code can be changed
          opacity: 0.1
        }
      }
    }
  };