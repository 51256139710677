import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import vsDark from "../../../../../../deck.theme";
import wtf from "../../../../../../images/WFTPM.png";
import wtfGif from "../../../../../../images/wtf2.gif";
import wtfDude from "../../../../../../images/wtf3.gif";
import allGif from "../../../../../../images/all.gif";
import intelisenseGood1 from "../../../../../../images/intelisense-funcionando.png";
import intelisenseGood2 from "../../../../../../images/intelisense-funcionando2.png";
import intelisenseBad1 from "../../../../../../images/intelisense-nao-funcionando.png";
import intelisenseBad2 from "../../../../../../images/intelisense-nao-funcionando2.png";
import approvesGif from "../../../../../../images/approves.gif";
import feelsFrog from "../../../../../../images/feelsbad.png";
import brainExplosion from "../../../../../../images/brain-explosion.gif";
import slack from "../../../../../../images/slack.png";
import airbnb from "../../../../../../images/airbnb.png";
import openSource from "../../../../../../images/open-source.png";
import google from "../../../../../../images/google.png";
import damm from "../../../../../../images/damm.gif";
import magic from "../../../../../../images/magic.gif";
import approves2 from "../../../../../../images/approves2.gif";
import approves3 from "../../../../../../images/approves3.gif";
import approves4 from "../../../../../../images/approves4.gif";
import johnSmile from "../../../../../../images/john-smile.gif";
import React from 'react';
export default {
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  vsDark: vsDark,
  wtf: wtf,
  wtfGif: wtfGif,
  wtfDude: wtfDude,
  allGif: allGif,
  intelisenseGood1: intelisenseGood1,
  intelisenseGood2: intelisenseGood2,
  intelisenseBad1: intelisenseBad1,
  intelisenseBad2: intelisenseBad2,
  approvesGif: approvesGif,
  feelsFrog: feelsFrog,
  brainExplosion: brainExplosion,
  slack: slack,
  airbnb: airbnb,
  openSource: openSource,
  google: google,
  damm: damm,
  magic: magic,
  approves2: approves2,
  approves3: approves3,
  approves4: approves4,
  johnSmile: johnSmile,
  React: React
};